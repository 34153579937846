/*
 * SonarQube
 * Copyright (C) 2009-2024 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.badge {
  display: inline-block;
  padding: 4px;
  background-color: #e6e6e6;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  color: #333;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 8px;
}
.badge:empty {
  display: none;
}

a.badge:hover,
a.badge:focus,
a.badge:active {
  text-decoration: underline;
}
a.badge {
  border-bottom: none;
}

.list-group-item-heading > .badge {
  float: right;
  margin: 3px;
}
.list-group-item-heading > .badge + .badge {
  margin-right: 5px;
}

.badge-info {
  background-color: #d9edf7;
  color: #0e516f;
}

.badge-success {
  background-color: #dff0d8;
  color: #215821;
}

.badge-warning {
  background-color: #fcf8e3;
  color: #6f4f17;
}

.badge-error {
  background-color: #f2dede;
  color: #862422;
}

.counter-badge {
  color: #FFFFFF;
  background-color: #2E7CB5;
  font-size: 12px;
  padding: 0.3em 0.8em;
  border-radius: 1em;
}

.counter-badge:empty {
  display: none;
}
